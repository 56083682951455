<template>
  <BouyguesError
    v-if="true"
    @try-again="router.push({ name: CHECKOUT.SERVICES.BOUYGUES.SIGN_UP })"
  />
</template>

<script setup lang="ts">
import { navigateTo, useRoute, useRouter } from '#imports'
import { onUnmounted } from 'vue'

import type { HttpApiError } from '@backmarket/http-api'
import { getBouyguesToken } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { clearNuxtState, useState } from 'nuxt/app'

import BouyguesError from '~/scopes/checkout/components/BouyguesError/BouyguesError.vue'

import { CHECKOUT } from '../../routes-names'
import { useBouyguesAuthorize } from '../BouyguesSignUp/composables/useBouyguesAuthorize'

const { redirectUri } = useBouyguesAuthorize()
const route = useRoute()
const router = useRouter()
const logger = useLogger()

const hasError = useState('bouygues-authentication-error')

if (!hasError.value) {
  const authorizationCode = route.query?.code

  // if there's no code on the url query params
  if (!authorizationCode || typeof authorizationCode !== 'string') {
    hasError.value = true
    logger.error('No Bouygues authentication code provided in url', {
      owners: ['bot-squad-b2cservices-front'],
    })
  } else {
    // Get access token
    try {
      const { accessToken } = await $httpFetch(getBouyguesToken, {
        body: { authorizationCode, redirectUri },
      })

      await navigateTo({
        name: CHECKOUT.SERVICES.BOUYGUES.PHONELINE_SETUP,
        query: { accessToken },
      })
    } catch (err) {
      const error = err as HttpApiError
      hasError.value = true
      logger.error(error.message, {
        error,
        owners: ['bot-squad-b2cservices-front'],
      })
    }
  }
}

onUnmounted(() => clearNuxtState('bouygues-authentication-error'))
</script>
