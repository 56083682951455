import { createHttpEndpoint } from '../../utils'

import type { BouyguesAuthenticationToken } from './types/bouygues-authentication-token'
import type {
  CustomerAccountEligibility,
  CustomerAccountStatus,
} from './types/mobile-plan-customer-account'
import type { MobilePlanOffer } from './types/mobile-plan-offers'

/**
 *
 * Create mobile plan customer account
 *
 * https://devportal.backmarket.io/catalog/default/api/mobile-plan-api/definition#/customer-account/create_customer_account
 */
export const createCustomerAccount = createHttpEndpoint({
  method: 'POST',
  operationId: 'createCustomerAccount',
  path: '/mobile-plan/api/v1/customer-account',
})

/**
 *
 * Get mobile plan offers
 *
 * https://devportal.backmarket.io/catalog/default/api/mobile-plan-api/definition#/offers/get_offers
 */
export const getMobilePlanOffers = createHttpEndpoint<MobilePlanOffer[]>({
  method: 'GET',
  operationId: 'getMobilePlanOffers',
  path: '/mobile-plan/api/v1/offers',
})

/**
 *
 * Get mobile plan offer by its id
 *
 * https://devportal.backmarket.io/catalog/default/api/mobile-plan-api/definition#/offers/get_offer
 */
export const getMobilePlanOffer = createHttpEndpoint<MobilePlanOffer>({
  method: 'GET',
  operationId: 'getMobilePlanOffer',
  path: '/mobile-plan/api/v1/offers/:offerId',
})

/**
 *
 * Check customer's email
 *
 * https://devportal.backmarket.io/catalog/default/api/mobile-plan-api/definition#/customer-account/get_customer_account_status
 */
export const checkMobilePlanCustomerAccountStatus =
  createHttpEndpoint<CustomerAccountStatus>({
    method: 'POST',
    operationId: 'checkMobilePlanCustomerAccountStatus',
    path: '/mobile-plan/api/v1/customer-account/status',
  })

/**
 *
 * Get Customer Account Eligibility
 *
 * https://devportal.backmarket.io/catalog/default/api/mobile-plan-api/definition#/customer-account/get_customer_account_eligibility
 */
export const checkMobilePlanCustomerAccountEligibility =
  createHttpEndpoint<CustomerAccountEligibility>({
    method: 'POST',
    operationId: 'checkMobilePlanCustomerAccountEligibility',
    path: '/mobile-plan/api/v1/customer-account/eligibility',
  })

/**
 *
 * Get Bouygues Access Token
 *
 * https://devportal.backmarket.io/catalog/default/api/mobile-plan-api/definition#/authentication/get_bouygues_access_token
 */
export const getBouyguesToken = createHttpEndpoint<BouyguesAuthenticationToken>(
  {
    method: 'POST',
    operationId: 'getBouyguesToken',
    path: '/mobile-plan/api/v1/auth/bouygues-token',
  },
)

/**
 * Save the Bouygues address or get normalization errors
 *
 * https://devportal.backmarket.io/catalog/default/api/mobile-plan-api/definition#/customer-account/post_address
 *
 */

export const saveBouyguesAddress = createHttpEndpoint({
  method: 'POST',
  operationId: 'saveBouyguesAddress',
  path: '/mobile-plan/api/v1/customer-account/address',
})
