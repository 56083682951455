import { useLinkResolver, useRuntimeConfig } from '#imports'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { CHECKOUT } from '~/scopes/checkout/routes-names'

export function useBouyguesAuthorize() {
  const runtimeConfig = useRuntimeConfig()
  const { getLink } = useLinkResolver()
  const {
    market: { countryCode },
  } = useMarketplace()

  const clientId = runtimeConfig.public.BOUYGUES_TELECOM_OAUTH_CLIENT_ID
  const redirectUri = getLink({
    link: { name: CHECKOUT.SERVICES.BOUYGUES.AUTH },
    countryCode,
  })?.href

  if (!redirectUri) throw new Error('Incorrect Bouygues oauth2 redirect_uri')

  const authorizeUrl = new URL(
    runtimeConfig.public.BOUYGUES_TELECOM_OAUTH_AUTHORIZE_ENDPOINT_URL,
  )

  authorizeUrl.searchParams.append('client_id', clientId)
  authorizeUrl.searchParams.append('response_type', 'code')
  authorizeUrl.searchParams.append('redirect_uri', redirectUri)

  return { redirectUri, authorizeUrl }
}
